// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Icon } from '@zaveit/icons';
import classNames from 'classnames';
import { getMenu } from 'api-client/index';
import AvatarMenu from 'components/AvatarMenu';
import LangMenu from 'components/LangMenu';
import { truncateText } from 'helpers/truncateText';
import Menu from 'components/Menu/Menu';
import { MenuIcon } from 'components/Menu/MenuIcon';
import { saveData } from 'helpers/localStorageHelper';
import Chip from '@material-ui/core/Chip';
import { getAccountSetting } from 'api-client/health';
import { SideBarModes } from 'components/constants';
import defaultConfig from 'config';
import Notifications from 'components/Notifications';
import { RightMenuIcon } from 'components/Icons';
import DemoMenu from 'components/DemoMenu';
import HelpMenu from 'components/HelpMenu';
import useStyles from './Header.styles';

type TProps = {
  handleLogout?: () => void | undefined;
  setIsSidebarOpened?: (value: boolean) => void;
  setIsRightSidebarOpened?: (value: boolean) => void;
  handleOpenRightSidebar?: () => void;
  colors?: any;
  logoSrc?: string;
  logoPath?: string;
  session?: boolean;
  companyName?: string;
  sideBarMode?: SideBarModes;
  isRightSidebarOpened?: boolean;
  setSideBarMode?: (value: SideBarModes) => void;
  handleGettingStartedClick?: () => void;
  showGettingStarted?: boolean;
  trigerOpenOnSession?: boolean;
  isSidebarOpened?: boolean;
  userInfo?: {
    firstname?: string;
    lastname?: string;
    username?: string;
    msp?: boolean;
    account?: {
      name: string;
    };
  };
  langs?: string[];
  userLang?: string;
  handleGetMenu?: () => void;
  isRightSidebarDisabled?: boolean;
  setUserLang?: (value: string) => void;
  showMenu?: boolean;
  urlConfig?: {
    [key: string]: string;
  };
  createHref?: (item: any) => string;
  rightMenuSidebarData?: any;
  // rightMenuSidebarData = {
  //   tab: string;
  //   content: {
  //     title: string;
  //     iconName: string;
  //     onClick: () => void;
  //     type: string;
  //   }[];
  //   bottomActions: {
  //     title: string;
  //     onClick: () => void;
  //     className: string;
  //   }[];
  // };
};

const Header = ({
  handleLogout,
  colors,
  logoSrc,
  logoPath,
  session,
  userInfo,
  handleGetMenu,
  companyName,
  setIsSidebarOpened,
  setIsRightSidebarOpened,
  isRightSidebarOpened,
  setSideBarMode,
  sideBarMode,
  handleOpenRightSidebar,
  isRightSidebarDisabled,
  urlConfig,
  showGettingStarted,
  handleGettingStartedClick,
  setUserLang,
  userLang,
  langs,
  trigerOpenOnSession,
  isSidebarOpened,
  showMenu,
  createHref,
  rightMenuSidebarData,
}: TProps) => {
  const classes = useStyles({ colors });
  const [menu, setMenu] = useState(null);
  const [isLogoError, setIsLogoError] = useState(false);

  const isOpened =
    window.location.pathname === '/dashboard/getting-started' ? false : isSidebarOpened;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentPath = window.location.pathname;

  const setSidebarOpen = useCallback(
    (value) => {
      setOpen(value);
      setIsSidebarOpened(value);
      saveData('sidebarOpen', value);
    },
    [setIsSidebarOpened],
  );

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setSidebarOpen(isOpen);
    if (isOpen && isRightSidebarOpened) {
      setSideBarMode(SideBarModes.ICONS_VIEW);
    }
  };

  const handleGetMenuData = useCallback(async () => {
    try {
      setLoading(true);
      let data = [];
      if (handleGetMenu) {
        data = await handleGetMenu();
      } else {
        data = await getMenu(urlConfig);
      }
      setMenu(data);
      if (!data?.length) {
        setSidebarOpen(false);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, [handleGetMenu, setSidebarOpen]);

  const handleGetAccountSettings = useCallback(async () => {
    try {
      const { data } = await getAccountSetting(urlConfig);
      if (data?.isConfigured === false && userInfo?.msp === true) {
        window.location.replace(`${window.location.origin}/order/country-settings`);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, [urlConfig, userInfo]);

  useEffect(() => {
    setSidebarOpen(isOpened);
  }, [isOpened, setSidebarOpen]);

  useEffect(() => {
    if (session) {
      handleGetMenuData();
      handleGetAccountSettings();
      if (showGettingStarted) {
        setSidebarOpen(false);
      } else if (trigerOpenOnSession) {
        setSidebarOpen(isOpened);
      }
    }
  }, [
    session,
    handleGetMenuData,
    setSidebarOpen,
    showGettingStarted,
    trigerOpenOnSession,
    handleGetAccountSettings,
  ]);

  const handleRedirect = () => {
    if (!session && window.location.href.includes(`${window.location.origin}/order`)) {
      window.location.replace(`${window.location.origin}/order`);
    } else window.location.replace(`${window.location.origin}/dashboard`);
  };

  const logoUrl = logoSrc || (logoPath ? `${urlConfig.baseFileUrl}${logoPath}` : '');
  const logo =
    logoUrl && !isLogoError ? (
      <div role="button" className={classes.logoBtn} aria-hidden="true" onClick={handleRedirect}>
        <img
          src={logoUrl}
          alt="logo"
          className={classes.logo}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            setIsLogoError(true);
          }}
        />
      </div>
    ) : (
      <div className={classes.logo} />
    );

  const handleChangeSidebarMode = () => {
    if (sideBarMode === SideBarModes.ICONS_VIEW) {
      setSideBarMode(SideBarModes.FULL_VIEW);
    } else {
      setSideBarMode(SideBarModes.ICONS_VIEW);
    }
  };

  return (
    <Router>
      <div className={classes.headerContainer}>
        <div className={classes.burgerMenuContainer}>
          {session && (
            <>
              {menu && menu?.length && showMenu ? (
                <div
                  role="button"
                  aria-hidden="true"
                  onClick={toggleDrawer(!open)}
                  className={classes.menuButton}
                >
                  <MenuIcon fill={colors?.header_icons} />
                </div>
              ) : (
                <div className={classes.menuButtonHidden} />
              )}
              {menu && menu?.length && showMenu ? (
                <div
                  className={classNames({
                    [classes.menuContainer]: true,
                    [classes.menuContainerIconView]: sideBarMode === SideBarModes.ICONS_VIEW,
                  })}
                >
                  {open && (
                    <div
                      className={classes.arrowBtnContainer}
                      id={sideBarMode === SideBarModes.FULL_VIEW ? 'arrow-btn' : ''}
                    >
                      <div
                        role="button"
                        aria-hidden="true"
                        className={classNames({
                          [classes.arrowBtn]: true,
                          [classes.arrowBtnfullView]: sideBarMode === SideBarModes.FULL_VIEW,
                        })}
                        onClick={handleChangeSidebarMode}
                      >
                        <Icon iconName="chevron_right" />
                      </div>
                    </div>
                  )}
                  <Menu
                    menu={menu}
                    path={currentPath}
                    open={open}
                    setIsSidebarOpened={setSidebarOpen}
                    setIsRightSidebarOpened={setIsRightSidebarOpened}
                    loading={loading}
                    sideBarMode={sideBarMode}
                    setSideBarMode={setSideBarMode}
                    colors={colors}
                    createHref={createHref}
                    urlConfig={urlConfig}
                  />
                </div>
              ) : null}
            </>
          )}
          {logo}
        </div>
        <nav className={classes.navContainer}>
          <div className={classes.chip}>
            {userInfo && (
              <Chip
                size="small"
                variant="outlined"
                label={truncateText(userInfo?.account?.name, 20)}
              />
            )}
          </div>

          <DemoMenu urlConfig={urlConfig} />
          <HelpMenu color={colors?.header_icons} route="https://docs.zaveit.io" />
          {Boolean(langs?.length) && (
            <LangMenu
              langs={langs}
              userLang={userLang}
              setUserLang={setUserLang}
              color={colors?.header_icons}
            />
          )}
          {session && <Notifications iconColor={colors?.header_icons} urlConfig={urlConfig} />}
          {session && (
            <AvatarMenu
              handleLogout={handleLogout}
              avatarColor={colors?.header_background}
              iconColor={colors?.header_icons}
              userInfo={userInfo}
              companyName={companyName}
              urlConfig={urlConfig}
              handleGettingStartedClick={handleGettingStartedClick}
              showGettingStarted={showGettingStarted}
            />
          )}
          <button
            className={classes.moreIconMenuBtn}
            onClick={() => (handleOpenRightSidebar ? handleOpenRightSidebar() : () => {})}
            type="button"
            disabled={!handleOpenRightSidebar}
          >
            <div className={classes.rightMenuIconWrapper}>
              <RightMenuIcon
                fill={!handleOpenRightSidebar ? 'rgba(148, 157, 176, 0.8)' : colors?.header_icons}
                style={{ height: '100%', width: '100%' }}
              />
              <span
                className={`${classes.notificationDot} ${
                  rightMenuSidebarData?.contentData?.find((item) => item.tab === 'Accounts')
                    ?.selectedOption // check if selected
                    ? classes.notificationDotVisible
                    : ''
                }`}
              />
            </div>
          </button>
        </nav>
      </div>
    </Router>
  );
};

Header.defaultProps = {
  handleLogout: null,
  colors: null,
  logoSrc: '',
  logoPath: '',
  session: false,
  userInfo: {
    firstname: '',
    lastname: '',
    username: '',
    msp: false,
    account: {
      name: '',
    },
  },
  handleGetMenu: null,
  createHref: null,
  companyName: '',
  setIsSidebarOpened: () => {},
  setIsRightSidebarOpened: () => {},
  sideBarMode: SideBarModes.FULL_VIEW,
  isRightSidebarOpened: false,
  setSideBarMode: () => {},
  handleOpenRightSidebar: null,
  handleGettingStartedClick: () => {},
  isRightSidebarDisabled: false,
  urlConfig: defaultConfig,
  showGettingStarted: false,
  trigerOpenOnSession: true,
  isSidebarOpened: false,
  setUserLang: () => {},
  userLang: 'EN',
  langs: [],
  showMenu: true,
  rightMenuSidebarData: {},
};

export default Header;
