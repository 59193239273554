import { getRisc, getFindings } from 'api-client/risc';
import { getCustomers } from 'api-client/user';
import { Ranges, ServicesStatuses, DefaultSortingValue } from 'components/constants';
import {
  getExtendedAccount,
  getAccounts,
  getCustomerSeries as getCustomerHistory,
} from 'api-client/account';
import { getCustomerTickets, getTicketHistory, getTicketsAfterDate } from 'api-client/support';
import { TFinding } from 'components/types';
import dayjs from 'dayjs';
import { getDeliveryByStatus } from 'api-client/delivery';

import { getOrderHistory, getOrderByStatus } from '../../api-client/order';
import { getFindingByRisc, getRiscHistory } from '../../api-client/risc';

const transformHistoryData = (historyData, dataKey, amountKey = null) => {
  if (!historyData) return { data: [], date: [], amount: [] };
  return historyData.reduce(
    (acc, item) => {
      acc.data.push(item[dataKey]);
      acc.date.push(item.date);
      if (amountKey) acc.amount.push(item[amountKey]);
      return acc;
    },
    { data: [], date: [], amount: [] },
  );
};

const createDailyStatus = (statuses) =>
  statuses.map(({ title, count, redirectPath }) => ({
    title,
    message: count > 0 ? 'need attention' : 'all clear',
    count,
    redirectPath,
  }));

export const getSalesHistoryData = async () => {
  try {
    const salesHistory = await getOrderHistory({ days: 14 });
    return transformHistoryData(salesHistory, 'confirmedAmount', 'confirmedCount');
  } catch (error) {
    console.error('Error fetching sales history:', error);
    throw new Error('Failed to fetch sales history.');
  }
};

export const getTicketHistoryData = async (tenant) => {
  try {
    const ticketHistory = await getTicketHistory({ days: 14, tenant });
    const newTickets = await getTicketsAfterDate({
      date: dayjs().format('YYYY-MM-DD'),
      tenant,
    });
    return transformHistoryData(ticketHistory, 'totalOpen', newTickets.length);
  } catch (error) {
    console.error('Error fetching ticket history:', error);
    throw new Error('Failed to fetch ticket history.');
  }
};

export const getCustomerHistoryData = async () => {
  try {
    const customerHistory = await getCustomerHistory({ days: 14 });
    return transformHistoryData(customerHistory, 'total', 'value');
  } catch (error) {
    console.error('Error fetching customer history:', error);
    throw new Error('Failed to fetch customer history.');
  }
};

export const getRiscHistoryData = async (tenant) => {
  try {
    const riscHistory = await getRiscHistory({
      tenant,
      date: dayjs().subtract(7, 'day').format(),
      now: true,
    });
    return transformHistoryData(riscHistory, 'risc_score');
  } catch (error) {
    console.error('Error fetching RISC history:', error);
    throw new Error('Failed to fetch RISC history.');
  }
};

export const getDashboardData = async (tenant) => {
  try {
    const allPromises = {
      risc: getRisc(tenant),
      findings: getFindings(tenant, null, null, 100),
      tickets: getCustomerTickets({ status: 'open', tenant }),
      acceptedOrders: getOrderByStatus({ tenant, status: 'accepted' }),
      openDeliveries: getDeliveryByStatus({ status: 'open' }),
      findingByRisc: getFindingByRisc({
        tenant,
        status: 'open',
        risc: 8,
      }),
      customers: getCustomers(),
      accounts: getAccounts({ tenant }),
    };

    const results = await Promise.allSettled(
      Object.entries(allPromises).map(async ([key, promise]) => {
        const result = await promise;
        return { key, result };
      }),
    );

    const resolvedData: any = results.reduce((acc, res) => {
      if (res.status === 'fulfilled') {
        acc[res.value.key] = res.value.result;
      } else {
        console.warn(`Failed to fetch ${res.reason}`);
      }
      return acc;
    }, {});

    return {
      risc: resolvedData.risc,
      findings: resolvedData.findings,
      criticalFindings: resolvedData.findingByRisc,
      customers: resolvedData.customers,
      accounts: resolvedData.accounts?.data,
      tickets: resolvedData.tickets,
      dailyStatus: createDailyStatus([
        {
          title: 'Orders',
          count: resolvedData.acceptedOrders?.length,
          redirectPath: 'account-manager/orders?status=accepted',
        },
        {
          title: 'Open tickets',
          count: resolvedData.tickets?.length,
          redirectPath: 'v2/support/customer-tickets/',
        },
        {
          title: 'Open deliveries',
          count: resolvedData.openDeliveries?.length,
          redirectPath: 'account-manager/delivery/dashboard',
        },
        {
          title: 'Critical findings',
          count: resolvedData.findingByRisc?.data?.length,
          redirectPath: 'dashboard/findings',
        },
      ]),
    };
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw new Error('Failed to fetch dashboard data. Please try again later.');
  }
};

export const getCustomersData = async (tenant) => {
  try {
    const riscPromise = getRisc(tenant, true);
    const customersPromise = getExtendedAccount(tenant);

    const [riscData, customersData] = await Promise.all([riscPromise, customersPromise]);

    return {
      risc: riscData,
      customers: customersData?.data,
    };
  } catch (error) {
    return error;
  }
};

export const updateProviderFindingsData = (findings, customers) =>
  findings?.slice(Ranges.tables.min, Ranges.tables.max).map((finding) => ({
    ...finding,
    customer: customers?.find((customer) => customer.id === finding.metadata.child_tenant)?.name,
  }));

export const updateConsumerFindingsData = (findings, targets) => {
  return findings?.slice(Ranges.tables.min, Ranges.tables.max).map((finding) => ({
    ...finding,
    target: targets.find((target) => target._id === finding.metadata.target)?.title,
  }));
};

export const getTargetsData = (findingsData, backup = [], vsaas = []) => {
  const targetsData = [...backup, ...vsaas];
  const findings = new Map<string, TFinding>(
    findingsData?.map((finding) => [finding?.metadata?.target, finding]),
  );

  return targetsData
    ?.map((target) => {
      const currFinding = findings.get(target._id);
      3;
      return {
        _id: target?.id,
        title: target?.displayName || target?.name,
        user: target?.account?.name,
        metadata: { application: currFinding?.metadata?.application || 'default' },
        service: ServicesStatuses[currFinding?.metadata?.application]?.title,
        risc_score: currFinding?.risc_score,
      };
    })
    .sort((a, b) => (b.risc_score || DefaultSortingValue) - (a.risc_score || DefaultSortingValue))
    .slice(Ranges.tables.min, Ranges.tables.max);
};
