import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { Dropdown, DropdownMultiple } from '@zaveit/uikit';
import dayjs from 'dayjs';

import 'styles/fonts.scss';
import { formatTitle } from 'helpers/formatHelper';
import { AccountState, AuthState, RootState } from 'redux/types';
import { setStatusBar } from 'redux/statusBar/statusBar.actions';
import { useChartOptions } from 'components/hooks/useChartOptions';
import { getData, saveData } from 'helpers/localStorageHelper';
import { getConsumptionData, getConsumptionInfo } from 'api-client/consumption';
import { excludeExistingColors, getStartDateByPeriod } from 'components/helpers/consumption';
import { formatToChartData, getChartTitle } from 'helpers/consumption';

import useStyles from './Timeline.styles';
import { timelinePeriods } from '../constants';
import TimelineLegend from './TimelineLegend';
import { ChartColors } from './constants';

function Timeline() {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: RootState): AuthState => state.auth);
  const { selectedAccountView } = useSelector((state: RootState): AccountState => state.account);

  const [timelineData, setTimelineData] = useState(null);
  const [dataConfig, setDataConfig] = useState<any>({});
  const [timeLineDates, setTimeLineDates] = useState([]);
  const [timelineAllData, setTimelineAllData] = useState([]);
  const [selectedDropdownProperties, setSelectedDropdownProperties] = useState<string[]>([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [period, setPeriod] = useState<string>(timelinePeriods[0]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const chartOptions = useChartOptions({
    timeLineDates,
    selectedProperties,
    dataConfig,
    timelineData,
    isLoading,
  });
  const chartData = useMemo(
    () => (isLoading ? [] : formatToChartData(selectedProperties, dataConfig, timelineData)),
    [dataConfig, isLoading, selectedProperties, timelineData],
  );
  const dropdownOptions =
    Object.entries(dataConfig).map(
      ([key, value]: any) => value.label_name || formatTitle(value.name) || formatTitle(key),
    ) || [];

  const timelineLegendProperties = useMemo(
    () =>
      selectedProperties.map((property, index) => ({
        title: getChartTitle({ property, dataConfig, timelineData }),
        color:
          dataConfig?.[property]?.color ||
          excludeExistingColors({
            allColors: ChartColors,
            config: dataConfig,
            selectedProps: selectedProperties,
          })[index],
      })),
    [dataConfig, selectedProperties, timelineData],
  );

  const handleChangeTimeline = useCallback(({ data, timelineProperties }) => {
    const dates = [];
    const newData: any = {};

    data?.forEach((item) => {
      dates.push(dayjs(item.date).toISOString());
      timelineProperties.forEach((property) => {
        if (!newData[property]) {
          newData[property] = [];
        }
        newData[property] = [...newData[property], item[property]];
      });
    });
    setTimelineData(newData);
    setTimeLineDates(dates);
  }, []);

  const handleGetTimeline = useCallback(
    async ({ timelineProperties, timelinePeriod = period }) => {
      setIsLoading(true);
      try {
        const tenant = selectedAccountView?.tenant || userInfo.tenant;
        const { startDate, endDate } = getStartDateByPeriod(timelinePeriod);
        const consumptionData = await getConsumptionData({
          tenant,
          startDate,
          endDate,
          granularity: 'day',
        });
        setTimelineAllData(consumptionData?.data?.data);
        handleChangeTimeline({ data: consumptionData?.data?.data, timelineProperties });
      } catch (error) {
        dispatch(
          setStatusBar({
            type: 'error',
            message: error.response?.data?.message || 'Something went wrong! Please try again!',
            open: true,
          }),
        );
      }
      setIsLoading(false);
    },
    [dispatch, handleChangeTimeline, period, selectedAccountView?.tenant, userInfo.tenant],
  );

  const getConfig = useCallback(async () => {
    setIsLoading(true);
    try {
      const consumptionData = await getConsumptionInfo();
      const firstField: any = Object.entries(consumptionData?.fields)?.[0] || [];
      const defaultDropdownProperties =
        getData('consumptionTimeline_dropdownProperties') || [firstField[1]?.label_name] ||
        formatTitle(firstField[1]?.name) ||
        [];
      const defaultProperties = getData('consumptionTimeline_properties') || [firstField[0]] || [];
      setDataConfig(consumptionData?.fields);
      setSelectedDropdownProperties(defaultDropdownProperties);
      setSelectedProperties(defaultProperties);
      handleGetTimeline({ timelineProperties: defaultProperties });
    } catch (error) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: error.response?.data?.message || 'Something went wrong! Please try again!',
          open: true,
        }),
      );
      setIsLoading(false);
    }
  }, [dispatch, handleGetTimeline]);

  useEffect(() => {
    if (userInfo) {
      const defaultPeriod = getData('consumptionTimeline_period') || timelinePeriods[0];

      setPeriod(defaultPeriod);
      getConfig();
    }
  }, [getConfig, userInfo]);

  const handleChangeProperties = (e) => {
    const { value } = e.target || {};

    const newDropdownProperties = [];
    const newProperties = [];
    Object.entries(dataConfig).forEach(([key, val]) => {
      const title = (val as any).label_name || formatTitle((val as any).name) || formatTitle(key);
      if (value.includes(title)) {
        newDropdownProperties.push(title);
        newProperties.push(key);
      }
    });
    setSelectedDropdownProperties(newDropdownProperties);
    setSelectedProperties(newProperties);
    handleChangeTimeline({ data: timelineAllData, timelineProperties: newProperties });
    saveData('consumptionTimeline_dropdownProperties', newDropdownProperties);
    saveData('consumptionTimeline_properties', newProperties);
  };

  const handleChangePeriod = (e) => {
    const { value } = e.target || {};
    setPeriod(value);
    handleGetTimeline({ timelineProperties: selectedProperties, timelinePeriod: value });
    saveData('consumptionTimeline_period', value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.timelineHeader}>
        <div className={classes.timelineInfo}>
          <DropdownMultiple
            value={selectedDropdownProperties}
            label="Properties"
            options={dropdownOptions}
            onChange={handleChangeProperties}
          />
          <Dropdown value={period} options={timelinePeriods} onChange={handleChangePeriod} />
        </div>
        <TimelineLegend
          properties={timelineLegendProperties}
          handleChangeProperties={handleChangeProperties}
        />
      </div>
      <div className={classes.chartContainer}>
        {/* @ts-ignore */}
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={288} />
      </div>
    </div>
  );
}

export default Timeline;
