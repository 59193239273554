import { Container, Grid } from '@material-ui/core';
import { Feature } from '@zaveit/feature';
import { TFinding } from '@zaveit/uikit/dist/cjs/types/components/FindingDetailsModal/types';
import Findings from 'components/Findings/Findings';
import {
  getDashboardData,
  getCustomerHistoryData,
  getSalesHistoryData,
  getTicketHistoryData,
  getRiscHistoryData,
} from 'components/helpers';
import { useSelector } from 'react-redux';
import { TCustomer } from 'components/types';
import { Features } from 'constants/features';
import { useEffect, useState } from 'react';
import { TileData, DailyStatusItem } from 'types/home';
import Skeleton from '@mui/material/Skeleton';
import { UserToken } from '@zaveit/types';
import DataTile from './DataTiles/DataTile';
import DataTileRISC from './DataTiles/DataTileRISC';
import useStyles from './Home.styles';
import TaskItem from './Task/TaskItem';

export default function ProviderAdminView() {
  const { userInfo } = useSelector((state: { auth: { userInfo: UserToken } }) => state.auth);

  const classes = useStyles();

  const [salesHistory, setSalesHistory] = useState<TileData | null>(null);
  const [isSalesHistoryLoading, setIsSalesHistoryLoading] = useState<boolean>(true);

  const [customerHistory, setCustomerHistory] = useState<TileData | null>(null);
  const [isCustomerHistoryLoading, setIsCustomerHistoryLoading] = useState<boolean>(true);

  const [ticketHistory, setTicketHistory] = useState<TileData | null>(null);
  const [isTicketHistoryLoading, setIsTicketHistoryLoading] = useState<boolean>(true);

  const [riscHistory, setRiscHistory] = useState<TileData | null>(null);
  const [isRiscHistoryLoading, setIsRiscHistoryLoading] = useState<boolean>(true);

  const [dailyStatus, setDailyStatus] = useState<DailyStatusItem[]>([]);
  const [isDailyStatusLoading, setIsDailyStatusLoading] = useState<boolean>(true);

  const [criticalFindings, setCriticalFindings] = useState<object[] | null>(null);
  const [findings, setFindings] = useState<TFinding[]>([]);
  const [customers, setCustomers] = useState<TCustomer[]>([]);

  useEffect(() => {
    const fetchRiscHistory = async () => {
      try {
        const data = await getRiscHistoryData(userInfo.tenant);
        setRiscHistory(data);

        setIsRiscHistoryLoading(false);
      } catch (error) {
        console.error('Error fetching RISC history:', error);
      }
    };

    getDashboardData(userInfo.tenant)
      .then((data) => {
        setCriticalFindings(data.criticalFindings);
        setFindings(data.findings?.data || []);
        setCustomers(data.customers || []);
        setDailyStatus(data.dailyStatus || []);
      })
      .finally(() => setIsDailyStatusLoading(false));

    getSalesHistoryData()
      .then((data) => setSalesHistory(data))
      .finally(() => setIsSalesHistoryLoading(false));

    getCustomerHistoryData()
      .then((data) => setCustomerHistory(data))
      .finally(() => setIsCustomerHistoryLoading(false));

    getTicketHistoryData(userInfo.tenant)
      .then((data) => setTicketHistory(data))
      .finally(() => setIsTicketHistoryLoading(false));

    fetchRiscHistory();
  }, [userInfo?.tenant]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DataTile
            isLoading={isSalesHistoryLoading}
            buttonName="View orders"
            title="Sales today"
            message="confirmed orders"
            tooltipLabel="Total sales"
            data={salesHistory}
            redirectPath="/account-manager/orders"
            isCurrency={true}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DataTile
            isLoading={isCustomerHistoryLoading}
            buttonName="View customers"
            title="Customers"
            message="new customers"
            tooltipLabel="Customers"
            data={customerHistory}
            redirectPath="/account-manager/accounts"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DataTile
            isLoading={isTicketHistoryLoading}
            buttonName="View tickets"
            title="Open tickets"
            message="new tickets"
            tooltipLabel="Open tickets"
            data={ticketHistory}
            redirectPath="/v2/support/customer-tickets/"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DataTileRISC
            isLoading={isRiscHistoryLoading}
            title="RISC"
            findingsData={criticalFindings}
            message="critical findings"
            tooltipLabel="RISC"
            redirectPath="/findings"
            findings={findings}
            data={riscHistory}
          />
        </Grid>
      </Grid>

      {/* Task Items Section */}
      {isDailyStatusLoading ? (
        <div className={classes.skeletonContainer}>
          <Skeleton variant="rectangular" width="100%" height={150} />
        </div>
      ) : (
        <div className={classes.middleSection}>
          <div className={classes.taskContainer}>
            {dailyStatus.map((item, index) => {
              if (!item.count) return null;

              const isLastItem = index === dailyStatus.length - 1;
              const isFirstItem = index === 0;

              return (
                <TaskItem
                  key={item.title}
                  data={item}
                  // eslint-disable-next-line no-nested-ternary
                  lastIndex={isLastItem ? index : isFirstItem ? 0 : undefined}
                />
              );
            })}
          </div>
        </div>
      )}

      {/* Findings Section */}
      <Feature feature={Features.risc}>
        <Findings
          tenant={userInfo?.tenant}
          values={findings}
          customers={customers}
          handleChange={setFindings}
          isLoading={isDailyStatusLoading}
        />
      </Feature>
    </Container>
  );
}
