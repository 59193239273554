import axios, { AxiosResponse } from 'axios';
import { Context } from './types';

const getInstance = (config) =>
  axios.create({ baseURL: config.basePublicUrl, withCredentials: true });

export const getCategories = async (config) => {
  const instance = getInstance(config);
  try {
    const { data } = await instance.get('/v1/search/category');
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getCategory = async (category, search, config) => {
  const instance = getInstance(config);
  try {
    const url = search ? `/v1/search/${category}?q=${search}` : `/v1/search/${category}`;
    const { data } = await instance.get(url);
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getMenu = async (config) => {
  const instance = getInstance(config);
  try {
    const { data } = await instance.get('/v1/menu');
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getEnvironment = async (config) => {
  const instance = getInstance(config);
  try {
    const { data } = await instance.get('v1/index/environment');
    return data;
  } catch (err) {
    console.error(err);
  }
};

export async function getContexts(config: Record<string, string>): Promise<Context> {
  const instance = getInstance(config);
  try {
    const { data } = await instance.get<Context>('v2/auth/context');
    return data;
  } catch (err) {
    console.error(err);
  }
}
